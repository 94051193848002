<template>
  <BaseDropDown btn-class="btn transparent-grey pl-10 pr-10 pos-r" direction="rtl">
    <template #btn>
      <BaseIcon class="ic-18 grey" icon="bell" />
      <small v-if="counter" class="marker primary counter">{{ counter }}</small>
    </template>
    <template #body>
      <div class="notifications grid">
        <!-- Head -->
        <div class="notifications__head flex ai-c jc-sb pl-20 pr-20 pt-10 pb-10">
          <h2 class="title">Уведомления</h2>
          <button
            class="btn sm outline flex ai-c ggap-10 pr-5"
            v-tippy="'Пометить все уведомления как прочитанные'"
            :disabled="isLoad || !(rows && rows.length)"
            @click.prevent="readAllAction"
          >
            Прочитать все
            <span class="btn xsm rounded cube-20 p-0">
              <BaseLoad v-if="isLoadRead" class="rel sm" />
              <BaseIcon v-else class="ic-14 primary" icon="check" />
            </span>
          </button>
        </div>

        <!-- Body -->
        <div
          class="notifications__body pos-r"
          :class="{ 'no-pages': pages && pages.last_page < 2, 'no-pages': pages && pages.last_page === 1 }"
        >
          <BaseLoad v-if="isLoad" class="white-transparent" />
          <div v-if="rows && rows.length" class="notifications__body-scroll flex fd-c h-100 overflow-a">
            <Item v-for="item of rows" :key="item.id" :item="item" @updateRows="getRows" />
          </div>
          <div v-else class="ta-center flex fd-c ai-c jc-c h-100 ggap-10">
            <BaseIcon class="ic-24 grey" icon="bell" />
            <div class="t-grey-dark">Уведомлений нет</div>
          </div>
        </div>

        <!-- Foot -->
        <div v-if="pages && pages.last_page > 1" class="notifications__foot p-5">
          <BasePagination :isDisabled="isLoad" :pages="pages" v-model="currentPage" />
        </div>
      </div>
    </template>
  </BaseDropDown>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import { BaseLoad, BaseIcon, BaseDropDown, BasePagination } from '@/components'
import { $busEmit, socketConnect } from '@/plugins'
import Item from './Item.vue'

// Data
const store = useStore()
const isLoad = ref(false)
const isLoadRead = ref(false)
const currentPage = ref(1)
const filters = ref({
  lawyers: [],
  users: []
})
const oldFilters = ref({})

// Computed
const profile = computed(() => store.getters.profile)
const rows = computed(() => store.getters['notifications/rows'])
const pages = computed(() => store.getters['notifications/pages'])
const counter = computed(() => {
  const count = store.getters['notifications/count']
  return count > 99 ? '99+' : count
})

// Watch
watch(currentPage, () => getRows())

// Created
if (localStorage.getItem('choco_nfilters')) {
  filters.value = JSON.parse(localStorage.getItem('choco_nfilters'))
  oldFilters.value = JSON.parse(localStorage.getItem('choco_nfilters'))
}
getRows()
wsConnect()

// Methods
async function getRows(isHasLoad = true) {
  try {
    if (isHasLoad) isLoad.value = true
    const params = {
      page: currentPage.value
    }

    await store.dispatch('notifications/GET_ROWS', params)
    await store.dispatch('notifications/GET_COUNT')
  } finally {
    if (isHasLoad) isLoad.value = false
  }
}
async function readAllAction() {
  try {
    isLoadRead.value = true
    await store.dispatch('notifications/READ_ALL')
    await store.dispatch('notifications/GET_ROWS', { page: 1 })
    currentPage.value = 1
  } finally {
    isLoadRead.value = false
  }
}

// Methods:websocket
function wsConnect() {
  const name = `private-user.${profile.value?.id}`
  socketConnect.channel(name).listen('.NotificationCreatedPrivateEvent', (data) => {
    if (data?.message) {
      getRows()
      $busEmit('setToast', {
        type: 'blue',
        icon: 'bell',
        title: 'Новое уведомление',
        message: 'Вам пришло новое уведомление, откройте уведомления чтобы узнать больше!'
      })
    }
  })
}
</script>

<style lang="scss" scoped>
.notifications {
  &__head {
    position: relative;
    box-shadow: 0 1px 0 0 var(--bcolor);

    &-sort {
      &.asc > svg {
        transform: rotate(180deg);
      }
    }
  }

  &__body {
    width: 580px;
    min-height: 100px;
    max-height: calc(100vh - 300px);

    &.no-pages,
    &.no-pages > &-scroll {
      border-radius: 0 0 var(--br) var(--br);
    }
  }

  &__foot {
    position: relative;
    box-shadow: 0 -1px 0 0 var(--bcolor);
    background-color: var(--box);
    border-radius: 0 0 var(--br) var(--br);
  }
}
</style>
